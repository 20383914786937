import { Grid, Paper, styled } from '@mui/material'
import LandingImage from '../../../assets/images/landing-image.png';
import InfoCardContent from './CardContent/InfoCardContent';
import SignInContent from './CardContent/SignInContent';

import '../../pages/styles.css'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
  }));

const HeroSection = () => {

  return ( 
        <Grid 
            container 
            
            justifyContent='center' alignItems='center' textAlign='center'
            sx={{ 
                width:'100vw', 
                backgroundImage:`url(${LandingImage})`, 
                backgroundRepeat: 'no-repeat', 
                backgroundSize:'cover', 
                height:'100%', 
                margin: 'auto',
                padding: '2.5%'
            }}
        >
            <Grid justifyContent='center' alignItems='center' textAlign='center' container sx={{ display: {xs: 'none', md: 'flex'}, maxWidth:'1920px'}}>
                <Grid container justifyContent='center' alignItems='center' textAlign='center' item  xs={10} md={5} lg={4}
                sx={{ height:'100%', color: 'white', margin:'auto', padding:'auto' }} >
                    <Item>
                        <InfoCardContent />
                    </Item>
                </Grid>
                <Grid container justifyContent='center' alignItems='center' textAlign='center' item xs={10} md={5} lg={4}  
                sx={{ mt: {xs: ' 20px', md: 0}, width: "100%", height:'100%', margin:'auto', padding:'auto'}} >     
                    <Item>
                        <SignInContent />
                    </Item>                      
                </Grid>      
            </Grid>  
            <Grid justifyContent='center' alignItems='center' textAlign='center' container sx={{ display: {xs: 'block', md: 'none'}, maxWidth:'1920px'}}>
                <Grid container justifyContent='center' alignItems='center' textAlign='center' item xs={10} md={5} lg={4}  
                sx={{  width: "100%", height:'100%', margin:'auto', padding:'auto'}} >  
                    <Item>
                        <SignInContent />
                    </Item>                         
                </Grid>      
                <Grid container justifyContent='center' alignItems='center' textAlign='center' item xs={10} md={5} lg={4} 
                sx={{ mt: {xs: ' 20px', md: 0}, height:'100%', color: 'white', margin:'auto', padding:'auto' }} >
                    <Item>
                        <InfoCardContent />
                    </Item>
                </Grid>
            </Grid>  
        </Grid>

  )
}

export default HeroSection