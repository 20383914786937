import { Box } from "@mui/material"

export function Profile() {
    
    return (     
        <Box>
            <Box>
                This is the profile Page.
            </Box>
        </Box>
    )
}