import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Typography, ButtonBase, Box } from '@mui/material';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export function BigInfoBox( { SubTitle = "", Title = "", Content = "", Link = "", ImgSrc = "", ImgName = "" } ) {
  return (
    <Box sx={{ backgroundColor: '#fff8e1'}}>
      <Box sx={{width: {xs: '100vw', md: 0}, height: {xs: '2%', md: 0}, bgcolor: 'black'}}>--</Box>
      <Paper
        sx={{
          boxShadow: 'none',
          p: 2,
          margin: 'auto',
          maxWidth: 1350,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
        }}
      >
        <Grid container spacing={2} sx={{pb: {xs: 10, md: 0}, mb: {md: 5}, mt:5}}>
          <Grid item>
              <ButtonBase sx={{ width: {xs:'90vw', sm:'95vw', md:525}, height: {xs: '25vh', sm:'45vh', md: 305} }}>
              <Img alt={ImgName} src={ImgSrc} />
            </ButtonBase>
          </Grid>        
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                  <Typography gutterBottom variant="caption" component="div">
                  {SubTitle}
                </Typography>
                <Typography gutterBottom variant="h4" component="div" sx={{mt:5, px:5}}>
                  {Title}
                </Typography>    
                <Typography gutterBottom variant="body2" component="div" sx={{px:5}}>
                  {Content}
                </Typography>         
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: 'pointer', mb:1 }} variant="body2">
                  {Link}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export function BigInfoBoxFlippedY( { SubTitle = "", Title = "", Content = "", Link = "", ImgSrc = "", ImgName = "" } ) {
    return (
    <Box sx={{ backgroundColor: '#fff8e1'}}>
        <Box sx={{width: {xs: '100vw', md: 0}, height: {xs: '2%', md: 0}, bgcolor: 'black'}}>--</Box>
        <Paper
            sx={{
            boxShadow: 'none',
            p: 2,
            margin: 'auto',
            maxWidth: 1350,
            flexGrow: 1,
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
            }}
        >
            <Grid container spacing={2} sx={{mt: 5, mb:5}}>                      
              <Grid item xs={12} sm container>                
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                        <Typography gutterBottom variant="caption" component="div">
                        {SubTitle}
                        </Typography>
                        <Typography gutterBottom variant="h4" component="div" sx={{mt:5, px:5}}>
                        {Title}
                        </Typography>    
                        <Typography gutterBottom variant="body2" component="div" sx={{px:5}}>
                        {Content}
                        </Typography>         
                    </Grid>
                    <Grid item>
                        <Typography sx={{ cursor: 'pointer', mb:1 }} variant="body2">
                        {Link}
                        </Typography>
                    </Grid>
                  </Grid>            
              </Grid>
              <Grid item>
                  <ButtonBase sx={{ width: {xs:'90vw', sm:'95vw', md:525}, height: {xs: '25vh', sm:'45vh', md: 305} }}>
                      <Img alt={ImgName} src={ImgSrc} />
                  </ButtonBase>
              </Grid>
            </Grid>
        </Paper>
    </Box>
    );
  }
  
  export function BigInfoBoxNoImg( { SubTitle = "", Title = "", Content = "", Link = "" } ) {
    return (
      <Box>        
        <Paper
          sx={{
            boxShadow: 'none',
            p: 2,
            margin: 'auto',
            maxWidth: 1350,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
          }}
        >
          <Grid container spacing={2}>
                  
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                    <Typography gutterBottom variant="caption" component="div">
                    {SubTitle}
                  </Typography>
                  <Typography gutterBottom variant="h4" component="div" sx={{mt:5, px:5}}>
                    {Title}
                  </Typography>    
                  <Typography gutterBottom variant="body2" component="div" sx={{px:5}}>
                    {Content}
                  </Typography>         
                </Grid>
                <Grid item>
                  <Typography sx={{ cursor: 'pointer', mb:1 }} variant="body2">
                    {Link}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }