import { Box, Button, Dialog, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { BankAccount, selectBankAccounts, submitTransferRequest } from '../../features/bank-account/bankAccountSlice'
import { Demodal, useModal } from 'demodal'
import { muiDialog } from 'demodal/material-ui'

export const AddTransferModal = Demodal.create(({fromAccount}:{fromAccount: BankAccount}) => {
    const bankAccounts = useAppSelector(selectBankAccounts)
    const addTransferModal = useModal()
    const dispatch = useAppDispatch()
    const [transferAmount, setTransferAmount] = useState(0)
    const [recievingAccountId, setRecievingAccountId] = useState(fromAccount.accountId)

    const handleTransferRequest = async () => {
        await dispatch(submitTransferRequest({transfer: transferAmount, fromAccountId: fromAccount.accountId, toAccountId: recievingAccountId }))
        if (transferAmount > fromAccount.balance) {
            console.log("There aren't enough funds in this account to transfer.");
        }
        addTransferModal.close();
    }

  return (
    <Box>
        <Dialog 
            {...muiDialog(addTransferModal)}
            >                              
                <Box sx={{ bgcolor: '#fff8e1', height: '550px', mx: 'auto', p: '2.5%', pt: '50px' }}> 
                        <Box>
                            <Typography>Transfer From: {fromAccount.accountId}</Typography>    
                            <Typography>Transfer To: </Typography>       
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="To Account"
                                value={recievingAccountId}
                                onChange={(event)=>setRecievingAccountId(event.target.value as number)}
                            >
                            { bankAccounts.map((accountId) => 
                                <MenuItem value={accountId.accountId}>{accountId.accountId}</MenuItem>
                                )
                            }
                            </Select>                 
                            <Typography sx={{ mb: '15px' }}>How much would you like to Transfer:</Typography>                        
                            <TextField 
                                id="outlined-controlled"
                                label="Amount"
                                value={transferAmount}
                                onChange={(event)=>setTransferAmount(parseFloat(event.target.value))}
                            />
                            <Button 
                                onClick={()=>handleTransferRequest()}>
                                    Transfer
                            </Button>
                        </Box>
                    </Box>
        </Dialog>
    </Box>
  )
})