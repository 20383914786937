export type Desire = {
    description: string,
    id: number
}

export const desireInfo: Desire[] = [
    {
        description: "grow my money",
        id: 1
    },
    {
        description: "prevent fraud",
        id: 2
    },
    {
        description: "work on my credit",
        id: 3
    },
    {
        description: "buy a home",
        id: 4
    },    
];

export const purposeInfo: Desire[] = [
    {
        description: "sleep better at night",
        id: 1
    },
    {
        description: "feel more secure about my future",
        id: 2
    },
    {
        description: "buy a car",
        id: 3
    },
    {
        description: "protect the things that I love",
        id: 4
    },
]
