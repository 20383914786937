import {  SecureIdConfiguration } from "secureid-react";

export const CorsOriginLocal = "http://localhost:5173"
export const AuthServerUriLocal = "https://localhost:7286/api"
export const ResourceServerUriLocal = "https://localhost:44338/api"
export const GoogleClientIdLocal = "155808634168-r8bvautqeeopucck5utgp3tb9j5tr920.apps.googleusercontent.com"
export const GoogleLoginUriLocal = "http://localhost:5173/Login"  

export const CorsOrigin = "https://gtb.haroutunpanosyan.com"
export const AuthServerUri = "https://authzy.org/api"
export const ResourceServerUri = "https://api.gtb.haroutunpanosyan.com/api"
export const GoogleClientId = "155808634168-r8bvautqeeopucck5utgp3tb9j5tr920.apps.googleusercontent.com"
export const GoogleLoginUri = "https://gtb.haroutunpanosyan.com/Login"

export const LocalDevelopmentConfig: SecureIdConfiguration = {
    CorsOrigin: CorsOriginLocal, 
    AuthServerUri: AuthServerUriLocal, 
    ResourceServerUri: ResourceServerUriLocal, 
    GoogleClientId: GoogleClientIdLocal, 
    GoogleLoginUri: GoogleLoginUriLocal
}

export const ProductionConfig: SecureIdConfiguration = {
    CorsOrigin: CorsOrigin, 
    AuthServerUri: AuthServerUri, 
    ResourceServerUri: ResourceServerUri, 
    GoogleClientId: GoogleClientId, 
    GoogleLoginUri: GoogleLoginUri
}