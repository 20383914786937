import * as React from 'react';
import { Box, Typography, Container, AppBar, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom"
import Logo from '../../assets/icons/logo.png';
import LogoSmall from '../../assets/icons/logo-small.png';
import '../pages/styles.css'



const productsPages = [ 'Credit Cards', 'Checking', 'Savings', 'Automotive' ];
const getToKnowUsPages = [ 'About', 'Newsroom', 'Technology' ];


function Footer() {

  return (
    <AppBar position="static" elevation={8} sx={{pt: 5, pb: 3, bgcolor: '#202322', '&::-webkit-scrollbar': {display: 'none'}}}>
      <Container maxWidth="sm" sx={{width: 'auto', height: 'auto'}}>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 500,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >           
            <Link to={`/`}>
              <img src={Logo} alt="Logo" />
            </Link>  
          </Typography>
                       
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
            <Link to={`/`}>
              <img src={LogoSmall} alt="Logo" />
            </Link>
          </Typography>
            
            <Box sx={{ display: { xs: 'block', md: 'flex'}, ml: '30%', mt: {sx: '10%', md: '0'} }}>
              {/* Products Box */}
              <Box sx={{mx: 'auto', mt: {xs: '25%', md: '0'}, textAlign: { md: 'right'} }}>
              <Typography sx={{ml: { xs:'-125px', md: '-65px'}, mb: '35px', fontSize: '25px', fontWeight: '400'}}>Products</Typography>
              {productsPages.map((page) => (
                <MenuItem disableGutters key={page} sx={{overflow: 'hidden', bgcolor: 'none', p:1, '&:hover': { transform: 'Scale(1.2)'} }}>
                  <Typography sx={{  opacity: '80%' }}>
                    <Link style={{textDecoration: 'none'}} to={`/${page}`} className='navLinks'>                      
                      {page}  
                    </Link>                     
                  </Typography>                   
                </MenuItem>
              ))}
            </Box>

              {/* Get to know us Box */}
              
              <Box sx={{mx: 'auto', mt: {xs: '25%', md: '0'}}}>   
              <Typography sx={{ml: { xs:'-65px', md: '5px'}, mb: '35px', fontSize: '23px', fontWeight: '400'}}>Get To Know Us</Typography>           
              {getToKnowUsPages.map((page) => (
                <MenuItem disableGutters key={page} sx={{overflow: 'hidden', bgcolor: 'none', p:1, '&:hover': { transform: 'Scale(1.2)'} }}>
                  <Typography sx={{ opacity: '80%' }} >
                    <Link style={{textDecoration: 'none'}} to={`/${page}`} className='navLinks'>                      
                      {page}
                    </Link>                     
                  </Typography>                   
                </MenuItem>
              ))}
            </Box>              
          </Box>

      </Container>
    </AppBar>
  );
}
export default Footer;