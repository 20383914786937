import {Navigate, Outlet} from 'react-router-dom'
import { useAppSelector } from "../app/hooks";
import { selectAuthzyUserId, selectUserId } from '../features/user/userSlice';

export const ProtectedRoute = () => {
    const authzyUserId = useAppSelector(selectAuthzyUserId);

    if (!authzyUserId) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />
};