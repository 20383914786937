import * as React from 'react'
import axios from "axios"
import { ProductionConfig } from "../config"


export const createAxiosInstance = () => {
    const instance = axios.create()

    instance.defaults.headers.common["Content-Type"] = "application/json"
    instance.defaults.baseURL = `${ProductionConfig.ResourceServerUri}`
    instance.defaults.headers['Access-Control-Allow-Origin'] = `${ProductionConfig.CorsOrigin}`

    return instance
} 

export function createAuthenticatedAxiosInstance() {
    const apiToken = localStorage.getItem('apiToken')
    console.log('api token from local storage: ', apiToken)
    const instance = createAxiosInstance();
    instance.defaults.headers['Authorization'] = `bearer ${apiToken}`
    
    return instance
}