import { Box, Typography, Grid, Paper, styled, Button, Container } from '@mui/material'
import Cards from '../pages/landingPage/CardContent/Cards'
import ShakeOnIt from '../../assets/icons/ShakeOnIt.png';
import React from 'react'
import { MiniCardsNoButton } from './landingPage/CardContent/MiniCards';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const Checking = () => {
  return (
    <Box sx={{bgcolor:'#3a3c3d'}}>
      
        <Box sx={{display: {xs: 'block', lg:'flex'}, mx: {xs: '0', sm: '2%', md: '10%' }, pt: { xs: '5%', md: '5%'}}}>
          <Container>
          <Box sx={{display: {xs: 'block', lg:'flex'}, mx: {xs: '0', sm: '2%', md:'-10%'}}}>
            <Cards imageSource={ShakeOnIt} title = "Golden Time Checking" description = "The most popular checking account with all the banking essentials." 
            link = "#" linkTitle ="Open Now" bulletPoints={["Comes with Golden Time Overdraft Protection", "Ability to write checks and wire money"]} extraInformation="No Service Fee" />          
            <Cards imageSource={ShakeOnIt} title = "Golden Security Banking" description = "A superb checking account with no overdraft fees." 
            link = "#" linkTitle ="Open Now" bulletPoints={["Direct Deposit up to four business days early", "No paper checks to manage"]} extraInformation="$6.25 / mo." />       
            <Cards imageSource={ShakeOnIt} title = "Golden Time Premier Plus" description = "Get the most benefits and save money on counter checks, money orders and so much more." 
            link = "#" linkTitle ="Open Now" bulletPoints={["The benefits of previous tiers", "Perks for military members and social services workers with $0 Monthly Service Fee" 
            + "and no minimum deposit required"]} extraInformation="$15 / mo." />
          </Box>
          </Container>
        </Box>
      <Box sx={{width: '100vw', height: { xs: '245px', sm: '205px' }, bgcolor: '#fff8e1', mt: {xs: '10%', md: '5%'}}}>
        <Typography sx={{ px: { xs:'15px', sm:'40px'}, pt: { xs: '30px', sm: '40px'}, lineHeight: '48px', fontSize: { xs: '28px', sm: '38px'}, fontWeight: '600' }}>
          Our checkings & savings accounts are the best in the country. Guaranteed!
        </Typography>
      </Box>
      <Box sx={{mx: 'auto', mt: '5%'}} >
        <Grid container spacing={2} >
          <Grid item xs={4}>            
              <Item sx={{bgcolor: 'transparent', color: 'white', px:{xs: '1', md: '5',}, maxWidth: {xs: '58.5vw', sm: '100%', lg:'100%'}, height:{xs:'395px', sm: '335px', md:'300px', lg:'205px'}}} >
                <Box sx={{ height: '425px'}} >
                  <h3>Golden Time Checking</h3>              
                  <Typography variant='body2'>Our checkings & savings accounts are the best in the country. Guaranteed!</Typography>                  
                  <Button href="#" size="medium" variant='contained' sx={{ height: '75px', bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
                    '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}}}>
                        See account details
                  </Button>
                </Box>
              </Item>
          </Grid>
          <Grid item xs={4}>            
            <Item sx={{bgcolor: 'transparent', color: 'white', px:{xs: '1', md: '5'}, pb:'15px', maxWidth: {xs: '58.5vw', lg:'100%'}, height:{xs:'395px', sm: '335px', md:'300px', lg:'205px'}}}>
              <Box sx={{height: '425px'}}>
                <h3>Golden Time Secure</h3>
                <Typography variant='body2'>A superb checking account with no overdraft fees. Simple and easy to use.</Typography>               
                <Button href="#" size="medium" variant='contained' sx={{ height: '75px', bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
                    '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}}}>
                        See account details
                  </Button>
              </Box>
            </Item>            
          </Grid>
          <Grid item xs={4}>
          <Item sx={{bgcolor: 'transparent', color: 'white', px:{xs: '1', md: '5'}, pb:'15px', maxWidth: {xs: '58.5vw', lg:'100%'}, height:{xs:'395px', sm: '335px', md:'300px', lg:'205px'}}}>
            <Box sx={{ height: '425px'}}>
                <h3>Golden Time Premier Plus</h3>
                <Typography variant='body2'>Get the most benefits and save money, every step of the way. We promise!</Typography>
                <Box sx={{alignItems: 'flex-end', alignSelf:'flex-end', alignContent:'flex-end'}}>
                  <Button href="#" size="medium" variant='contained' sx={{ height: '75px', bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
                    '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}}}>
                        See account details
                  </Button>
                </Box>
              </Box>
            </Item>            
          </Grid>    

          {/* Section 1: Min Deposit Check Marks */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>No minimum deposit to open</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>          
            </Grid>

          {/* Section 2: Monthly Service Fee */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>Monthly Service Fee</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', color: 'white', bgcolor: 'transparent', minHeight:'210px'}}>
                <h3>No Service Fee</h3>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', color: 'white', bgcolor: 'transparent', minHeight:'210px'}}>
                <h3>$6.25 per mo.</h3>
                <h5>Flat Fee</h5>
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', color: 'white', bgcolor: 'transparent'}}>
                <h3>$15 per mo.</h3>
                <h5>Flat Fee</h5>
                <Typography variant='body2'>Fee does not apply to accounts registered as military personel and social service workers.</Typography>
                <h4><a>See How to Apply</a></h4>
              </Item>          
            </Grid>

            {/* Section 3: ATM Count */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>More than 200,000 ATMs and more than 6,000 branches globally.</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>          
            </Grid>

            {/* Section 4: Autopay and Mobile */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>Golden Time Online Full-time Service</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>          
            </Grid>

            {/* Section 5: GoldenTime - Quickly transfer and receive money */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>GoldenTime - Quickly transfer and receive money</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>Free</h3>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>$1.99 per transfer</h3>
                <h4>Remove Rates <a>See How</a></h4>
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>Free</h3>
              </Item>          
            </Grid>

            {/* Section 6: Early Direct Deposit */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>Early Direct Deposit</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h4>----</h4>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>Up to 2 days early!</h3>
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>Up to 5 days early!</h3>
              </Item>          
            </Grid>

            {/* Section 7: Money Order Fee */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>No Fees for money orders or chashier's checks</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>----</h3>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent'}}>
                <img style={{width: '65px'}} src={ShakeOnIt} alt="Check Mark" />
              </Item>          
            </Grid>

             {/* Section 8: Interest */}          
          <Grid item xs={12}>
            <Item sx={{textAlign:'left', px:5}}>
              <h2>Earns Interest</h2>
            </Item>
          </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>----</h3>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>Up to 5%</h3>
              </Item>            
            </Grid>
            <Grid item xs={4}>
              <Item sx={{px:'auto', bgcolor: 'transparent', color: 'white'}}>
                <h3>Up to 15%</h3>
              </Item>          
            </Grid>
        </Grid>        
      </Box>
      <Box sx={{ color: 'white'}}>
        <h2>Experience true banking with Golden Time</h2>
      </Box>
      <Container sx={{pb: {xs: '10%', md: '5%'}}}>
        <Box sx={{mx:'auto', mt: '5.5%'}}>
          <Box sx={{display: {xs: 'block', lg:'flex'}}}>
            <MiniCardsNoButton imageSource={ShakeOnIt} title="Convenient ATMs and Branches" 
                description="Access to 200,00 ATMs and more than 6,000 branches." link="#" />
            <MiniCardsNoButton imageSource={ShakeOnIt} title="Golden Time Mobile App" 
                description="Manage your money, deposit checks and pay bills or people from anywhere with Golden Time Mobile App." />
            <MiniCardsNoButton imageSource={ShakeOnIt} title="Card Lock" 
                description="Lock and unlock your debit card whenever you like." link="#" />
          </Box>
        </Box>
      </Container>
    </Box>
    
  )
}

export default Checking
