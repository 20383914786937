import * as React from 'react';
import { Box,  Typography, ImageList, ImageListItem } from '@mui/material';
import fordImg from '../assets/images/ford-250.png'
import cruiseImg from '../assets/images/cruise.png'
import closeBankImg from '../assets/images/closeBank.png'
import passportImg from '../assets/images/passport.png'
import flightImg from '../assets/images/flight.png'
import creditLimitImg from '../assets/images/creditLimit.png'
 
interface Item {
  id: number,
  img: string,
  title: string,
  content: string
}

type AvailableItems = Item[];

const ImageGrid = () => {

  return (
    <Box sx={{ width: '70vw', mx: 'auto', height: '100%' }}>
    <ImageList sx={{ display: {xs: 'flow', lg: 'grid'}, pt:10, pb: 10, px: 5 }}>
      {itemData.map(({id, img, title, content}) => (
        <ImageListItem key={id} sx={{ pb: 5, mx: 'auto', width: '90%', height: '90%', }}>
          <img 
            style={{borderRadius: '10px', display: 'block', width: '60%', marginLeft: 'auto', marginRight: 'auto' }}   
            src={img}
            alt={title}
            loading="lazy"
          />
          <Box sx={{ maxWidth: '600px', height: '100%', mx: 'auto'}}>
            <Typography sx={{ mt: '25px', fontWeight: '700', fontSize: '22px' }}>{title}</Typography>
            <Typography sx={{ mb: '15px', fontSize: '18px'}}>{content}</Typography>
          </Box>
        </ImageListItem>
      ))}
    </ImageList>
    </Box>
  );
}

export default ImageGrid;

const images = [fordImg, cruiseImg, closeBankImg, passportImg, flightImg, creditLimitImg]

const itemData : AvailableItems = [
  {
    id: 1,
    img: images[0],
    title: 'Does breaking a lease affect your credit?',
    content: 'Breaking a lease early may be damaging to your credit. Discover how your credit can be affected and ways of protecting it with GoldenTime PrestineProtection',
  },
  {
    id: 2,
    img: images[2],
    title: 'Does closing a bank account hurt your credit?',
    content: 'Typically it does not, but fine print can bite back. See how you can avoid being bitten by fine print.',
  },
  {
    id: 3,
    img: images[3],
    title: 'What is the 6-month passport rule?',
    content: 'Learn about the 6-month passport rule and how it can affect your travel plans.',
  },
  {
    id: 4,
    img: images[4],
    title: 'How far in advance can you book a flight?',
    content: 'Learn about how you can book a flight as early as 2 years.',
  },
  {
    id: 5,
    img: images[1],
    title: 'The ultimate cruise',
    content: 'Use this package to find the right cruise for you at a fraction of the cost.',
  },
  {
    id: 6,
    img: images[5],
    title: 'What happens if you go over your credit limit?',
    content: 'Having your credit card declined is one of several things that might happen when you go over your credit limit. Learn of the other possibilites and issues that may arise.',
  }, 
];
