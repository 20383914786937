import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import { createAuthenticatedAxiosInstance } from "../../api/axiosFactory"

export enum BankAccountType {
    Checking = 1,
    Savings,
    Mortgage,
    BusinessLoan,
    AutoLoan,
    PersonalLoan,
    CreditCard,
    Unknown = 42
}

export enum TransactionType {
  Deposit = 1,
  Withdrawal,
  Transfer, 
  Payment
}

export const BankAccountTypeStrings = [
    'Unknown',
    'Checking',
    'Saving',
    'Mortgage',
    'BusinessLoan',
    'AutoLoan',
    'PersonalLoan',
    'CreditCard'
]

export interface BankAccount {
  accountId: number,
  transactions: Transaction[],
  pagingDetails: TransactionPagingDetails,
  balance: number,
  limit?: number,
  accountTypeId: BankAccountType,
}

export interface Transaction {
    transactionId: number,
    transactionTypeId: number,
    amount: number,
    fromAccountId?: number,
    toAccountId?: number,
    dateCreated: string
}

export interface TransactionPagingDetails {  
  pageNumber: number,
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  pageSize: number,
  totalCount: number,
  totalPages: number
}

const defaultPagingDetails: TransactionPagingDetails = {
  pageNumber: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  pageSize: 0,
  totalCount: 0,
  totalPages: 0
}

const defaultBankAccount: BankAccount = {
  accountId: 0,
  transactions: [],
  pagingDetails: defaultPagingDetails,
  balance: 0,
  limit: 0,
  accountTypeId: BankAccountType.Unknown,
}

export interface BankAccountState {
  accounts: BankAccount[],
  status: 'idle' | 'loading' | 'success' | 'failed',
  newAccountDetails: BankAccount
}

const initialState: BankAccountState = {
  accounts: [],
  newAccountDetails: defaultBankAccount,
  status: 'idle'
  
}

export const getBankAccounts = createAsyncThunk(
  "bankaccounts/getBankAccounts",
  async () => {
    const instance = createAuthenticatedAxiosInstance();
    const getAccount = {
      method: 'GET',
      url: 'bankaccounts'
    }
    console.log(`instance in getBankAccounts`)
    console.log(instance)
    const response = await instance.request(getAccount)

    return { data: response.data }
  }
)

export const getTransactionHistory = createAsyncThunk(
  "bankaccounts/getTransactionHistory",
  async ({accountId, pageNumber, pageSize}:{accountId : number, pageNumber: number, pageSize: number}) => {
    const instance = createAuthenticatedAxiosInstance();
    const getHistory = {
      method: 'GET',
      url: `bankaccounts/${accountId}/history?pageNumber=${pageNumber}&pageSize=${pageSize}`      
    }
    const response = await instance.request(getHistory)
    console.log(response.data)
    return { data: response.data }
  }
)

export const createBankAccountRequest = createAsyncThunk(
    "bankaccounts/createBankAccountRequest",
        async ({accountType}:{accountType: BankAccountType}) => {
            const instance = createAuthenticatedAxiosInstance();
            const accountTypeRequest = {
            method: 'POST',
            url: 'bankaccounts/open-account',
            data: {
              accountTypeId: accountType,
            }
        }
        const response = await instance.request(accountTypeRequest)
        return { data: response.data, accountType: accountType}
    }
)
  
export const submitDepositRequest = createAsyncThunk(  
    "user/submitDepositRequest",
    async ({deposit, accountId}:{deposit : number, accountId: number}) => {      
      const instance = createAuthenticatedAxiosInstance();
      const depositRequest = {
        method: 'POST',
        url: `bankaccounts/${accountId}/deposit`,
        data: { amount: deposit }
      }
      const response = await instance.request(depositRequest)
      return response.data
    }
  )   

  export const submitWithdrawRequest = createAsyncThunk(  
    "user/submitWithdrawRequest",
    async ({withdraw, accountId}:{withdraw : number, accountId: number}) => {  
      const instance = createAuthenticatedAxiosInstance();    
      const withdrawRequest = {
        method: 'POST',
        url: `bankaccounts/${accountId}/withdraw`,
        data: { amount: withdraw }
      }
      const response = await instance.request(withdrawRequest)
      return response.data 
    }
  ) 

  export const submitTransferRequest = createAsyncThunk(  
    "user/submitTransferRequest",
    async ({transfer, toAccountId, fromAccountId}:{transfer : number, toAccountId: number | string, fromAccountId: number | string }) => {  
      const instance = createAuthenticatedAxiosInstance();    
      const transferRequest = {
        method: 'POST',
        url: `bankaccounts/transfer`,
        data: { amount: transfer, toAccountId: toAccountId, fromAccountId: fromAccountId }
      }
      const response = await instance.request(transferRequest)

      return response.data
    }
  ) 

  export const bankAccountSlice= createSlice({
    name: "bankaccount",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {   
      // Use the PayloadAction type to declare the contents of `action.payload`
      setBankAccountType(state, action: PayloadAction<BankAccountType>) {
        state.newAccountDetails.accountTypeId = action.payload
      },
      setBalance(state, action: PayloadAction<number>) {
        state.newAccountDetails.balance = action.payload
      },
      setLimit(state, action: PayloadAction<number>) {
        state.newAccountDetails.limit = action.payload
      },
      setAccountTypeId(state, action: PayloadAction<number>) {
        state.newAccountDetails.accountTypeId = action.payload
      },
      setBankAccountId(state, action: PayloadAction<number>) {
        state.newAccountDetails.accountId = action.payload
      },
      setBankAccounts(state, action: PayloadAction<BankAccountState>) {
        state.accounts = action.payload.accounts
      },   
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
          .addCase(submitDepositRequest.pending, (state) => {
            state.status = "loading"
          })
          .addCase(submitDepositRequest.fulfilled, (state, action) => {          
            state.status = "idle"

            state.newAccountDetails.balance += action.payload
            let accountIndex = state.accounts.findIndex((account) => account.accountId === action.payload.accountId)        
            state.accounts[accountIndex].balance = action.payload.newBalance
          })
          .addCase(submitDepositRequest.rejected, (state) => {
            state.status = "failed"
          })  
          .addCase(submitWithdrawRequest.pending, (state) => {
            state.status = "loading"
          })
          .addCase(submitWithdrawRequest.fulfilled, (state, action) => {          
            state.status = "idle"
            state.newAccountDetails.balance -= action.payload
            let accountIndex = state.accounts.findIndex((account) => account.accountId === action.payload.accountId )
            state.accounts[accountIndex].balance = action.payload.newBalance
          })
          .addCase(submitWithdrawRequest.rejected, (state) => {
            state.status = "failed"
          })
          .addCase(submitTransferRequest.pending, (state) => {
            state.status = "loading"
          })
          .addCase(submitTransferRequest.fulfilled, (state, action) => {          
            state.status = "idle"
            let fromAccountIndex = state.accounts.findIndex((account) => account.accountId === action.payload.fromAccountId )
            let toAccountIndex = state.accounts.findIndex((account) => account.accountId === action.payload.toAccountId )

            state.newAccountDetails.balance -= action.payload.fromAccountBalance
            state.newAccountDetails.balance += action.payload.toAccountBalance

            state.accounts[fromAccountIndex].balance = action.payload.fromAccountBalance                  
            state.accounts[toAccountIndex].balance = action.payload.toAccountBalance
          })
          .addCase(submitTransferRequest.rejected, (state) => {
            state.status = "failed"
          })
          .addCase(createBankAccountRequest.pending, (state) => {
            state.status = "loading"
          })
          .addCase(createBankAccountRequest.fulfilled, (state, action) => {          
            state.status = "idle"
            state.newAccountDetails.accountTypeId = action.payload.accountType
          })
          .addCase(createBankAccountRequest.rejected, (state) => {
            state.status = "failed"
          })
          .addCase(getBankAccounts.pending, (state) => {
            state.status = "loading"
          })
          .addCase(getBankAccounts.fulfilled, (state, action) => {          
            state.status = "idle"
            state.accounts = action.payload.data
          })
          .addCase(getBankAccounts.rejected, (state) => {
            state.status = "failed"
          })
          .addCase(getTransactionHistory.pending, (state) => {
            state.status = "loading"
          })
          .addCase(getTransactionHistory.fulfilled, (state, action) => {
            state.status = "idle"
            var payloadData = action.payload.data.transactions
            var accountIndex = state.accounts.findIndex(bankAcc => bankAcc.accountId == action.payload.data.bankAccountId)

            state.accounts[accountIndex].transactions = payloadData.data
            state.accounts[accountIndex].pagingDetails = {
              pageNumber: payloadData.pageNumber,
              hasNextPage: payloadData.hasNextPage,
              hasPreviousPage: payloadData.hasPreviousPage,
              pageSize: payloadData.pageSize,
              totalCount: payloadData.totalCount,
              totalPages: payloadData.totalPages
            }
   
          })
          .addCase(getTransactionHistory.rejected, (state) => {
            state.status = "failed"
          })
      //   .addCase(incrementAsync.pending, (state) => {
      //     state.status = "loading"
      //   })
      //   .addCase(incrementAsync.fulfilled, (state, action) => {
      //     state.status = "idle"
      //     state.value += action.payload
      //   })
      //   .addCase(incrementAsync.rejected, (state) => {
      //     state.status = "failed"
      //   })
    },
})



export const selectBankAccountType = (state: RootState) => state.bank.newAccountDetails.accountTypeId
export const selectBalance = (state: RootState) => state.bank.newAccountDetails.balance
export const selectLimit = (state: RootState) => state.bank.newAccountDetails.limit
export const selectAccountTypeId = (state: RootState) => state.bank.newAccountDetails.accountTypeId
export const selectBankAccountId = (state:RootState) => state.bank.newAccountDetails.accountId
export const selectBankAccount = (state:RootState) => state.bank
export const selectBankAccounts = (state:RootState) => state.bank.accounts
export const selectTotalPages = (state:RootState) => state.bank.accounts.find(bankAcc => bankAcc.pagingDetails)

export default bankAccountSlice.reducer