import Galaxy from './assets/images/galaxy-card.png';
import Constell from './assets/images/constellation-card.png'
import Cosmos from './assets/images/cosmos-card.png'
import GoldGoose from './assets/images/golden-goose-card.png'
import GoldPlant from './assets/images/golden-plant-card.png'
import GoldEgg from './assets/images/golden-egg-card.png'
import Orbit from './assets/images/orbit-card.png'
import Interplan from './assets/images/interplanetary-card.png'
import Milky from './assets/images/milkyway-card.png'
import Moon from './assets/images/moon-card.png'
import Nebula from './assets/images/neublua-card.png'
import Planet from './assets/images/planet-card.png'
import Saturn from './assets/images/saturn-card.png'
import Sun from './assets/images/sun-card.png'
import LightYear from './assets/images/light-year-card.png'
import Excellent from './assets/images/excellent.png'
import Good from './assets/images/good.png'
import Fair from './assets/images/fair.png'
import Poor from './assets/images/poor.png'

interface ICard {
    id: number,
    title: string,
    img: string,
    description: string,
    cardType: string,
    creditIcon: string,
    creditType: string,
    extraInformation: string,
    link: string,
    linkTitle: string
}

export const AllCards: ICard[] = [
  {
    id: 1,
    title: "Galaxy Card",
    img: Galaxy,
    description: "The most popular card with 0% APR for the first 24 months!",
    cardType: 'popular',
    creditIcon: Excellent,
    creditType: 'Excellent',
    extraInformation: '2.5% APR for the first year after 24 months and 8.5% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 2,
    title: "Planet Card",
    img: Planet,
    description: "Get the card you want with no annual fees Well",
    cardType: 'popular',
    creditIcon: Fair,
    creditType: 'Fair',
    extraInformation: '0% APR for the first year, 5% APR the second year and 12.5% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 3,
    title: "Nebula Card",
    img: Nebula,
    description: "The best card for building credit.",
    cardType: 'popular',
    creditIcon: Good,
    creditType: 'Good',
    extraInformation: '10% APR for the first year after 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 4,
    title: "Golden Goose Card",
    img: GoldGoose,
    description: "Can't qualify for any cards due to bad credit?",
    cardType: 'credit',
    creditIcon: Poor,
    creditType: 'Poor',
    extraInformation: '5% APR for first 24 months and 25% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 5,
    title: "Golden Egg Card",
    img: GoldEgg,
    description: "Does building credit feel slow? Get a hold of the Golden Egg and build fast!",
    cardType: 'credit',
    creditIcon: Fair,
    creditType: 'Fair',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 17,
    title: "Golden Plant Card",
    img: GoldPlant,
    description: "Got good credit but want to build faster?",
    cardType: 'credit',
    creditIcon: Good,
    creditType: 'Good',
    extraInformation: '5% APR after the first 24 months and 12% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 6,
    title: "Orbit Card",
    img: Orbit,
    description: "Let money orbit around you!",
    cardType: 'cash',
    creditIcon: Good,
    creditType: 'Good',
    extraInformation: '2.5% APR for the first 24 months and 8% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 12,
    title: "Golden Milky Way Card",
    img: Milky,
    description: "This card boasts a whopping 15% cash back on most purchases, and an insane 25% on select brands partnered with us.",
    cardType: 'cash',
    creditIcon: Excellent,
    creditType: 'Excellent',
    extraInformation: '0% APR for the first 24 months and 2.5% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 7,
    title: "Cosmos Card",
    img: Cosmos,
    description: "With some serious dining & entertainment rewards. No cashback, but cosmic discounts on a lot of options.",
    cardType: 'rewards',
    creditIcon: Fair,
    creditType: 'Fair',
    extraInformation: '15% APR for the first 24 months and 25.5% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 8,
    title: "Constellation Card",
    img: Constell,
    description: "with dining & entertainment rewards",
    cardType: 'rewards',
    creditIcon: Good,
    creditType: 'Good',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 9,
    title: "Light-Year Card",
    img: LightYear,
    description: "with travel points",
    cardType: 'travel'
    ,creditIcon: Good,
    creditType: 'Good',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 10,
    title: "Interplanetary Card",
    img: Interplan,
    description: "with travel points",
    cardType: 'travel',
    creditIcon: Excellent,
    creditType: 'Excellent',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 11,
    title: "Orbit Card",
    img: Orbit,
    description: "with travel points",
    cardType: 'travel',
    creditIcon: Fair,
    creditType: 'Fair',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 13,
    title: "Saturn Card",
    img: Saturn,
    description: "for certain retailers",
    cardType: 'retail',
    creditIcon: Fair,
    creditType: 'Fair',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 14,
    title: "Moon Card",
    img: Moon,
    description: "for certain retailers",
    cardType: 'retail',
    creditIcon: Fair,
    creditType: 'Fair',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
  {
    id: 15,
    title: "Sun Card",
    img: Sun,
    description: "for certain retailers",
    cardType: 'retail'
    ,creditIcon: Good,
    creditType: 'Good',
    extraInformation: '10% APR after the first 24 months and 20% thereafter.',
    link: '#',
    linkTitle: 'Card Info'
  },
]

export const CardInfo = [
  {
      id: 1,
      description: "that are popular",
      creditType: 'popular'
  },
  {
      id: 2,
      description: "for building credit",
      creditType: 'credit'
  },
  {
      id: 3,
      description: "with cash back",
      creditType: 'cash'
  },
  {
      id: 4,
      description: "with dining & entertainment rewards",
      creditType: 'rewards'
  },
  {
      id: 5,
      description: "with travel points",
      creditType: 'travel'
  },
  {
      id: 6,
      description: "for certain retailers",
      creditType: 'retail'
  },
]
