import * as React from 'react';
import { Box, Button, Dialog, Popover } from "@mui/material"
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { BankAccount, BankAccountTypeStrings, getBankAccounts, getTransactionHistory, selectBankAccount, selectBankAccountId, selectBankAccounts } from '../../features/bank-account/bankAccountSlice';
import { AddDepositModal } from './Deposit';
import { selectApiToken, selectAuthzyUserId, selectUserId } from '../../features/user/userSlice';
import { Link } from 'react-router-dom';
import { muiDialog } from 'demodal/material-ui';
import { Demodal } from 'demodal';
import { useEffect, useState } from 'react';
import { AddWithdrawModal } from './Withdraw';
import { AddTransferModal } from './Transfer';
import { AddTransactionHistoryModal } from './TransactionHistory';



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'none',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .25)'  
}));

interface AccordItems {
    title: string,
    bankAccount: BankAccount
}

type CreditCard = {
  currentBalance: number,
  outstandingBalance: number,
  creditLimit: number,
  creditCardNumber: number
}

function InternalAccordion( { bankAccount, title }: { bankAccount:BankAccount, title:string } ) {
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
   
  const dispatch = useAppDispatch();

  const handleGetTransactions = async ({pageNumber, pageSize}:{pageNumber: number, pageSize: number}) => {
    await dispatch(getTransactionHistory({accountId: bankAccount.accountId, pageNumber: pageNumber, pageSize: pageSize})).then(()=>{

      Demodal.open(AddTransactionHistoryModal, {accountId: bankAccount.accountId})
    })         
  }   


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };    

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    };

  const handleClose = () => {
    setAnchorEl(null);
    };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={{  
        pt: '2.5%', 
        pb: '.5%' 
        }}
    >
      <Accordion sx={{ 
            mx: '20%', 
            border: '5px solid rgba(0 , 0, 0, .45)', 
            bgcolor: '#202322',  
            borderRadius: '10px',
            color: 'rgb(255, 255, 220)'            
            }} 
            expanded={expanded === 'panel1'} 
            onChange={handleChange('panel1')}
        >
        <AccordionSummary 
            aria-controls="panel1d-content" 
            id="panel1d-header"
        >
            <Typography sx={{
            fontSize: {xs: '20px', md:'30px'},
            letterSpacing: '.15rem'
            }}
            >
                {title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ 
            display: {xs: 'block', md: 'flex'}, 
            mx: 'auto', 
            p: '15px', 
            }}
        >
          <Typography sx={{ 
            fontSize: '25px',
            letterSpacing: '.15rem' 
            }}
            >
            Available Balance: <span style={{ 
                fontSize: '35px', 
                fontWeight: 'bold'
                }}
                >
                ${bankAccount.balance}
            </span>
          </Typography>     
          <Button sx={{ 
              ml: {xs: '0', md:'15%'},
              pb: '15px', 
              fontSize: '25px', 
              verticalAlign: 'center ', 
              width: '35px', 
              height: '35px', 
              color: 'rgb(255, 255, 200, 0.65)' 
            }}
            onClick={handleClick}
            >
              ...
          </Button>
          <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >                          
            <Button 
              sx={{ display: 'block', p: 2, mb: 2, mx: 2 }}
              onClick={()=>{Demodal.open(AddDepositModal, {selectedAccount: bankAccount})}}>
                Deposit
            </Button>
            <Button 
              sx={{ display: 'block', p: 2, mb: 2, mx: 2 }}
              onClick={()=>{Demodal.open(AddWithdrawModal, {selectedAccount: bankAccount})}}>
                Withdraw
            </Button>
            <Button 
              sx={{ display: 'block', p: 2, mb: 2, mx: 2 }}
              onClick={()=>{Demodal.open(AddTransferModal, {fromAccount: bankAccount, toAccount: bankAccount})}}>
                Transfer
            </Button>
            <Button 
                //handleGetTransactions({pageNumber: 1, pageSize: 25})}
              sx={{ display: 'block', p: 2, mb: 2, mx: 2 }}
              onClick={()=>{handleGetTransactions({pageNumber: 1, pageSize: 25})}}> 
                Transaction History
            </Button>
          </Popover>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}



const Dashboard = () => {
  const bankAccounts = useAppSelector(selectBankAccounts)
  const userId = useAppSelector(selectUserId)
  const authzyUserId = useAppSelector(selectAuthzyUserId)
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    getAccounts()
  },[])  
  
  const getAccounts = async () => { 
    (await dispatch(getBankAccounts()))
  }
  
    return (
      <Box sx={{ bgcolor: '#f4f5e1', p: '2.5%' }}>
        { userId && authzyUserId && bankAccounts ?            
          <Box>   
            <Box>
               { 
              <Box>
                { bankAccounts.map((account) =>  
                  <Box key={account.accountId}>
                    <InternalAccordion 
                      title={BankAccountTypeStrings[account.accountTypeId] + " " + account.accountId}                  
                      bankAccount={account}
                    />
                  </Box>
                  )
                }
                </Box>
                }
              </Box>
          </Box>
          :
          <Box>
            <Typography>No bank account associated with this user.</Typography>        
            <Link to="/OpenAccount">Open An Account</Link>  
          </Box>
        }      
          <Box>       
            <Link to="/OpenAccount">Open New Account</Link>  
          </Box>
      </Box>
    )
  }

  export default Dashboard