import HeroSection from './HeroSection'
import WhyUsSection from './WhyUsSection'
import { DesireSelect, PurposeSelect} from './DesireSection'
import { BigInfoBox, BigInfoBoxFlippedY } from './BigInfoBox'
import MoneyImg from "../../../assets/images/MoneyFactory.png"

const LandingPage = () => {
  
  return (
    <div>
      <HeroSection />
      <WhyUsSection />
      <DesireSelect title="I want to" />
      <PurposeSelect />
      <BigInfoBox SubTitle = "DEALS FOR MEMBERS" Title = "Automatically get better deals when you shop online or in person." 
      Content = "Golden time boasts up to a whopping 25% discount on select stores and major brands!" Link = "Become a Golden Time Shopper" ImgSrc = {MoneyImg} ImgName = "Money Factory" />
      <BigInfoBoxFlippedY SubTitle = "SAVINGS UNLIKE ANY OTHER" Title = "Amazing annual returns" 
      Content = "GoldenTime Savings brings about a wonderful 15% to 25% APY!" ImgSrc = {MoneyImg} ImgName = "Money Factory" Link="Become a Golden Time Savings Member" />
    </div>
  )
}

export default LandingPage
