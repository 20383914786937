import { Box, Typography } from "@mui/material";
import React from "react";

const Technology = () => {

    return (
        <Box sx={{ mx: 'auto', color: 'white', bgcolor: '#403e3b' }}>
            <Typography variant="h2" sx={{ p: '2%' }}>Tech</Typography>
            <Typography variant="body1" sx={{ pb: '25px' }}>Golden Time Banking proprietery blend of nonexistence.</Typography>
        </Box>
    )
}

export default Technology;