import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { createAxiosInstance } from "../../api/axiosFactory";

const instance = createAxiosInstance();

export interface UserState {
  userId: number | undefined,
  authzyUserId: number | undefined,
  status: 'idle' | 'loading' | 'success' | 'failed'
}

const initialState: UserState = {
  userId: undefined,
  authzyUserId: undefined,
  status: "idle"
}

export const loginToRemoteResource = createAsyncThunk(
  "user/loginToRemoteResource",
  async ({apiToken}:{apiToken: string}) => {
    const loginRequest = {
      method: 'POST',
      url: 'users/login',
      headers: {
        Authorization: `bearer ${apiToken}`
      }
    }

    const response = await instance.request(loginRequest)

    console.log(response.data)
    return {data: response.data, apiToken: apiToken}
  },
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {   
    // Use the PayloadAction type to declare the contents of `action.payload`
    logout(state) {
      state.authzyUserId = undefined,
      state.userId = undefined 
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
        .addCase(loginToRemoteResource.pending, (state) => {
          state.status = "loading"
        })
        .addCase(loginToRemoteResource.fulfilled, (state, action) => {          
          state.status = "idle" 
          console.log('Hooray we succeeded in logging into remote resource')
          console.log(action.payload)         
          state.userId = action.payload.data.userId
          state.authzyUserId = action.payload.data.authzyUserId

          console.log('action payload: ', action.payload);
          
          instance.defaults.headers['Authorization'] = `bearer ${action.payload.apiToken}`
          localStorage.setItem('apiToken', action.payload.apiToken)
        })
        .addCase(loginToRemoteResource.rejected, (state) => {
          state.status = "failed"
        })
    //   .addCase(incrementAsync.pending, (state) => {
    //     state.status = "loading"
    //   })
    //   .addCase(incrementAsync.fulfilled, (state, action) => {
    //     state.status = "idle"
    //     state.value += action.payload
    //   })
    //   .addCase(incrementAsync.rejected, (state) => {
    //     state.status = "failed"
    //   })
  },
})

export const selectUserId = (state:RootState) => state.user.userId
export const selectAuthzyUserId = (state:RootState) => state.user.authzyUserId
export const selectApiToken = (state:RootState) => state.auth.apiToken
export const selectAuth = (state:RootState) => state.auth
export default userSlice.reducer