import * as React from 'react';
import { Box, IconButton, Typography, Container, Avatar, Button, AppBar, Toolbar, Menu, Tooltip, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { loginToRemoteResource, selectApiToken, selectAuth, selectAuthzyUserId, selectUserId } from '../../features/user/userSlice';
import HamburgerIcon from '../../assets/icons/Hamburger-Icon.png'
import Logo from '../../assets/icons/logo.png';
import LogoSmall from '../../assets/icons/logo-small.png';
import '../pages/styles.css'

const loggedOutPages = [ 
  'Checking', 
  'Savings', 
  'Credit Cards', 
  'Loans', 
  'Automotive', 
  'Invest', 
  'Education' 
];

const loggedInPages = [ 
  'Accounts', 
  'Pay & Transfer'
];


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const auth = useAppSelector(selectAuth);
  const apiToken = useAppSelector(selectApiToken)
  const userId = useAppSelector(selectUserId)
  const authzyUserId = useAppSelector(selectAuthzyUserId)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const settings = [
    { title: 'Profile', onSelectHandler: ()=>{navigate("Profile")}}, 
    { title: 'Account Management' as 'AccountManagement', onSelectHandler: ()=>{navigate("AccountManagement")}},
    { title: 'Dashboard', onSelectHandler: ()=>{navigate("Dashboard")}},
    { title: 'Logout', onSelectHandler: ()=>{handleLogout()} }
  ];

  const handleLoginToRemoteResource = async () => {
    await dispatch(loginToRemoteResource({ apiToken: apiToken }))
    navigate("/Dashboard")
  }

  useEffect(()=> {
    if (apiToken) {
      handleLoginToRemoteResource()
    }
  },[apiToken])
    
  const handleLogout = async () => {
    await dispatch({
      type: 'auth/logout',
      payload: { auth, apiToken }
  })
    await dispatch({ 
      type: 'user/logout',
      payload: { userId, authzyUserId }
  })
    localStorage.removeItem("apiToken")
    navigate("/");
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" elevation={5} sx={{pt: 5, pb: 3, bgcolor: '#202322', '&::-webkit-scrollbar': {display: 'none'}}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>         
        
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >       

        {/* Redirect For PC Display's */}
            { authzyUserId && userId ?      
              <Link to={`Dashboard`}>              
                <img src={Logo} alt="Logo" />
              </Link>
              :
              <Link to={`/`}>              
                <img src={Logo} alt="Logo" />
              </Link>               
            } 
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              
            >
              <Avatar alt="Hamburger Icon" src={HamburgerIcon} sx={{transition: 'transform .3s', '&:hover': {transform: 'Scale(1.3)'} }} />             
            </IconButton>
            <Box sx={{pt: 5}} />
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorEl={anchorElNav}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ root: {"& .MuiPaper-root": {overflowY: 'none'}, '&.MuiPopover-root': {overflowY: 'none'}} , overflowY:'hidden', bgcolor: 'black', width: '225px', 
              opacity: '85%', height: '100vh', mt: '208px' , transition: 'transform .3s', 
                display: { xs: 'block', md: 'none' }
              }}          
              MenuListProps={{
                disablePadding: true,  
                sx: { overflow: 'hidden' },                               
              }}
              style={{overflow: 'hidden'}}
              disablePortal
            >   

            {/* Section for Phone Display's     */}
            { authzyUserId && userId ?  
              <Box sx={{ bgcolor: 'black', px: '15px' }}>
              {loggedInPages.map((page) => (
                <MenuItem disableGutters key={page} onClick={handleCloseNavMenu} sx={{ bgcolor: 'black', overflowY: 'hidden', '&:hover': { bgcolor: 'black' } }}>
                  <Typography sx={{ fontSize: '24px' }}>
                    <Link to={`/${page}`} className='navLinks'>                      
                      {page}  
                    </Link>                     
                  </Typography>                   
                </MenuItem>
              ))} 
              </Box>
              :
              <Box sx={{ px: '15px', bgcolor: 'black'}}>
              {loggedOutPages.map((page) => (
                <MenuItem disableGutters key={page} onClick={handleCloseNavMenu} sx={{ bgcolor: 'black', overflowY: 'hidden', '&:hover': { bgcolor: 'black' } }}>
                  <Typography sx={{ fontSize: '24px' }}>
                    <Link to={`/${page}`} className='navLinks'>                      
                      {page}  
                    </Link>                     
                  </Typography>                   
                </MenuItem>
              ))}
              </Box>
            }
            </Menu>
          </Box>          
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
            }}
          >
        {/* Redirect for Phone Display's */}
            { authzyUserId && userId ?        
              <Link to={`Dashboard`}>              
                <img src={LogoSmall} alt="Logo" />
              </Link>
              :
              <Link to={`/`}>              
                <img src={LogoSmall} alt="Logo" />
              </Link>
            } 
          </Typography>
          
          { authzyUserId && userId && 
          <Box sx={{ flexGrow: 1 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, transition: 'transform .3s', '&:hover': {transform: 'Scale(1.5)'} }}>
                <Avatar alt="Remy Sharp" src="" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >              
              {settings.map((setting) => (
                <MenuItem key={setting.title} 
                onClick={()=> {
                  handleCloseUserMenu(),
                  setting.onSelectHandler()
                  }}
                  >
                  <Typography textAlign="center">{setting.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          }
        </Toolbar>
        
        {/* Section for PC display's */}
        { authzyUserId && userId ?  
            <Box sx={{  display: { xs: 'none', md:'inline-flex'}, bgcolor: 'transparent' }}>
              {loggedInPages.map((page) => (
                <MenuItem disableGutters key={page} onClick={handleCloseNavMenu} 
                sx={{overflowY: 'hidden', p:0, mx: '15px', '&:hover': { transform: 'Scale(1.2)'}}}
                >
                  <Typography sx={{ml: 2}}>
                    <Link to={`/${page}`} className='navLinks'>                      
                      {page}  
                    </Link>                     
                  </Typography>                   
                </MenuItem>
              ))} 
              </Box>
              :
              <Box sx={{ display: { xs: 'none', md:'inline-flex'}, bgcolor: 'transparent' }}>
              {loggedOutPages.map((page) => (
                <MenuItem disableGutters key={page} onClick={handleCloseNavMenu} 
                sx={{overflowY: 'hidden', p:0, mx: '15px', '&:hover': { transform: 'Scale(1.2)'}}}
                >
                  <Typography sx={{ml: 2}}>
                    <Link to={`/${page}`} className='navLinks'>                      
                      {page}  
                    </Link>                     
                  </Typography>                   
                </MenuItem>
              ))}
            </Box>
            }
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;