import React from 'react'
import { Button, Card, CardContent, Typography, CardActions, CssBaseline, CardMedia } from "@mui/material"

export function MiniCards ( { imageSource = "", title = "", description = "", link = "", linkTitle ="", cardType = "" } ) {
  return (
    <div style={{ marginLeft:"2.5%", marginRight:"2.5%", opacity: '0.8'}}>
        <CssBaseline />
        <Card sx={{ width: 325, height:325, p: '25px', mt: '10%', mx: 'auto', borderRadius: '15px', bgcolor: 'black', color: 'white' }}>
            <CardMedia>
                <img src={imageSource} alt={title} style={{maxHeight:'75px'}} />
            </CardMedia>
            <CardContent>
                <Typography gutterBottom variant="h5">
                    {title}
                </Typography>
                <Typography variant="body2">
                    {description}
                </Typography>
            </CardContent>
                <Button href={link} size="medium" variant='contained' sx={{bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
                '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}}}>
                    {linkTitle} &#8594;
                </Button>
        </Card>
    </div>
  )
}

export function MiniCardsNoButton ( { imageSource = "", title = "", description = "", link = "", linkTitle ="" } ) {
    return (
      <div style={{ marginLeft:"2.5%", marginRight:"2.5%", opacity: '0.8'}}>
          <CssBaseline />
          <Card sx={{ width: 325, height:325, p: '25px', mt: '2.5%', mx: 'auto', borderRadius: '15px', bgcolor: 'black', color: 'white' }}>
              <CardMedia>
                  <img src={imageSource} alt={title} style={{maxHeight:'75px'}} />
              </CardMedia>
              <CardContent>
                  <Typography gutterBottom variant="h5">
                      {title}
                  </Typography>
                  <Typography variant="body2">
                      {description}
                  </Typography>
              </CardContent>                 
          </Card>
      </div>
    )
  }


  export function CreditCards ( data:any ) {
    return (
      <div style={{ marginLeft:"2.5%", marginRight:"2.5%", opacity: '0.8'}}>
          <CssBaseline />
          {data.map((info: any) => (
          <Card key={info} sx={{ width: 325, height:325, p: '25px', mt: '10%', mx: 'auto', borderRadius: '15px', bgcolor: 'black', color: 'white' }}>
              <CardMedia>
                  <img src={info.img} alt={info.title} style={{maxHeight:'75px'}} />
              </CardMedia>
              <CardContent>              
                  <Typography gutterBottom variant="h5">
                      {info.title}
                  </Typography>
                  <Typography variant="body2">
                      {info.description}
                  </Typography>                  
              </CardContent>
                  <Button href={info.link} size="medium" variant='contained' sx={{bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
                  '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}}}>
                      {info.linkTitle} &#8594;
                  </Button>
          </Card>     
          ))}     
      </div>
    )
  }