import { Box } from '@mui/material'
import React from 'react'
import ImageGrid from '../ImageGrid'

const Education = () => {
  return (
    <Box sx={{ bgcolor: '#f4f5e1' }}>
      <ImageGrid />
    </Box>
  )
}

export default Education
