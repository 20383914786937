import { Box, Button, Dialog, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch } from '../../app/hooks'
import { BankAccount, submitWithdrawRequest } from '../../features/bank-account/bankAccountSlice'
import { Demodal, useModal } from 'demodal'
import { muiDialog } from 'demodal/material-ui'

export const AddWithdrawModal = Demodal.create(({selectedAccount}:{selectedAccount: BankAccount}) => {
    const addWithdrawModal = useModal()
    const dispatch = useAppDispatch()
    const [withdrawAmount, setWithdrawAmount] = useState(0)

    const handleWithdrawRequest = async () => {
        await dispatch(submitWithdrawRequest({withdraw: withdrawAmount, accountId: selectedAccount.accountId}))
        addWithdrawModal.close();
    }

  return (
    <Box>
        <Dialog 
            {...muiDialog(addWithdrawModal)}
            >                              
                <Box sx={{ bgcolor: '#fff8e1', height: '550px', mx: 'auto', p: '2.5%', pt: '50px' }}> 
                        <Box>
                            <Typography>Currently Withdrawing from account number: {selectedAccount.accountId}</Typography>
                            <Typography sx={{ mb: '15px' }}>How much would you like to Withdraw:</Typography>                        
                            <TextField 
                                id="outlined-controlled"
                                label="Amount"
                                value={withdrawAmount}
                                onChange={(event)=>setWithdrawAmount(parseFloat(event.target.value))}
                            />
                            <Button 
                                onClick={()=>handleWithdrawRequest()}>
                                Withdraw
                            </Button>
                        </Box>
                    </Box>
        </Dialog>
    </Box>
  )
})