import * as React from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, createTheme, ThemeProvider, Typography, Container, Card, CardMedia, CardContent, Button, Grid, styled, Paper } from '@mui/material';
import GTCard from '../../assets/images/golden-time-card-landing.png';
import { AllCards, CardInfo } from '../../CreditCardInfo';

  const theme = createTheme({
    components: {       
      MuiSelect: {      
        styleOverrides: {
          icon: ({ theme }) =>
            theme.unstable_sx({
              color: 'white',
              fill: 'white',
              fontSize: '35px'
            }),    
          root: ({ theme }) =>
            theme.unstable_sx({
              color: 'white',
              fill: 'white',
            }),   
        },          
      },      
    }
  });

  const CreditCards= ()=> {
    const [selectedCardType, setSelectedCardType] = React.useState('popular'); // State to hold the selected cardType

    const handleSelect = (event: SelectChangeEvent) => {
        setSelectedCardType(event.target.value);
    };

    return (
      <Box>                
        <img src={GTCard} style={{ width: '100%' }} />
        <Container sx={{ pb: '5%'}}>  
            <Box>
                <ThemeProvider theme={theme}>
                    <Box sx={{ color: 'white', mt:{xs: '6%', sm:'10%', xl:'13%'}, display: {xs: 'block', sm: 'inline-flex'} }}>
                        <h1>Show me cards</h1>  
                            <FormControl variant="standard" sx={{ mt:2, mx: 5, minWidth: 320 }}>  
                            <Select
                            value={selectedCardType}   
                            onChange={handleSelect}
                            sx={{ fontSize:'30px' }}
                            >   
                            { CardInfo.map((cardSelect) =>
                                <MenuItem  
                                key={cardSelect.id}
                                value={cardSelect.creditType}
                                >
                                  {cardSelect.description}
                                </MenuItem>
                                )}
                            </Select>
                        </FormControl> 
                    </Box>                     
                </ThemeProvider>
            </Box>
            </Container>        
            <Container>
            <Box sx={{ display: {xs: 'inline-block', lg: 'flex'}, mx: 'auto', mb: '5%'}}>
          { AllCards.filter((chosenCards) =>  
          chosenCards.cardType.includes(selectedCardType)).map((items) => 
          ( 
              <Box key={items.id} sx={{ mx: 'auto'}}>                
                <Box sx={{opacity: '0.8', mb: '5%', mx: 'auto' }} >                    
                    <Card sx={{ display: 'grid' , width:'320px', height:565, p: '25px', borderRadius: '15px', bgcolor: 'black', color: 'white' }}>
                        <CardMedia>
                            <img src={items.img} alt={items.title} loading='lazy' style={{ maxWidth: '100%', height: 'auto' }} />                                              
                        </CardMedia>                             
                        <Typography gutterBottom variant="h3" sx={{fontSize: '28px'}}>
                            {items.title}
                        </Typography>                
                        <CardContent sx={{alignSelf:'flex-start'}}>                    
                            <Typography variant="h5" sx={{ fontSize: '18px', fontWeight: '400', textAlign: 'left' }}>
                                {items.description}
                            </Typography>
                            <Typography variant='body2' sx={{pt: 1}}>  </Typography>
                        </CardContent>
                             <Box sx={{alignSelf:'flex-end'}}>
                                <Typography sx={{ fontSize: '14px', textAlign: 'left', overflow: 'hidden', bgcolor: 'none', pl:2 }}>
                                    <img src={items.creditIcon} alt={items.creditIcon} /> {items.creditType}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', textAlign: 'left', overflow: 'hidden', bgcolor: 'none', pl:2, pb:2 }}>
                                    {items.extraInformation}
                                </Typography>
                                <Button href={items.link} size="medium" variant='contained' sx={{ bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
                                '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}}}>
                                    {items.linkTitle} &#8594;
                                </Button>
                            </Box>                     
                        </Card>
                    </Box>
              </Box>  
              
          ))}            
        </Box>
        </Container>
        <Box sx={{ width: '100vw', height: '10vh', bgcolor: 'white', verticalAlign: 'middle'}}>
          <Typography variant='h4' sx={{ verticalAlign: 'middle', p: '1.8%' }}>
            NOT WHAT YOU'RE LOOKING FOR?
          </Typography>
        </Box>
        <Box sx={{ width: '100vw', height: '10vh', bgcolor: 'grey', pt: '5%'}}>
          <Typography variant='h6'>
            Hold nothing back. No impact to your credit score. Everything done right.
          </Typography>
        </Box>  
      </Box>
    )
  }

  export default CreditCards;