import { Box, Button, Dialog, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { BankAccount, selectBankAccounts, submitDepositRequest } from '../../features/bank-account/bankAccountSlice'
import { Demodal, useModal } from 'demodal'
import { muiDialog } from 'demodal/material-ui'

export const AddDepositModal = Demodal.create(({selectedAccount}:{selectedAccount: BankAccount}) => {
    const addDepositModal = useModal()
    const dispatch = useAppDispatch()
    const [depositAmount, setDepositAmount] = useState<String | Number>('')

    const handleDepositRequest = async () => {
        await dispatch(submitDepositRequest({deposit: depositAmount as number, accountId: selectedAccount.accountId}))
        addDepositModal.close()                
    }

  return (
    <Box>
        <Dialog 
            {...muiDialog(addDepositModal)}
            >                              
                <Box sx={{ bgcolor: '#fff8e1', height: '550px', mx: 'auto', p: '2.5%', pt: '50px' }}> 
                        <Box>
                            <Typography>Currently depositing to account number: {selectedAccount.accountId}</Typography>
                            <Typography sx={{ mb: '15px' }}>How much would you like to deposit:</Typography>                        
                            <TextField 
                                id="outlined-controlled"
                                label="Amount"
                                value={depositAmount}
                                defaultValue=''
                                onChange={(event)=>setDepositAmount(parseFloat(event.target.value) as number)}
                            />
                            <Button 
                                onClick={()=>handleDepositRequest()}>
                                Deposit
                            </Button>
                        </Box>
                    </Box>
        </Dialog>
    </Box>
  )
})