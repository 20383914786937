import { useState } from 'react';
import { Box, Button, CardContent, Grid, Modal, TextField } from '@mui/material'
import { Login, SignUp } from 'secureid-react';
import Logo from 'secureid-react/dist/logo-G6BYFCJT.png'

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const SignInContent = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%', mx: 'auto', borderRadius: '15px', bgcolor: '#202322', p: '5%'}}>       
        <Login bgColor="transparent" txtColor='white' btnBgColor='black' btnTxtColor='white' btnHoverBgColor='white' btnHoverTxtColor='black' boxWidth='100%' logo={Logo} /> 
        <Box sx={{ display: 'block', width: '100%' }}>
            <Button onClick={handleOpen} size="small" sx={{ color:'white', bgcolor:'transparent', display:'block', mt:'25px', mb: 0, opacity: '100%', mx:'auto', 
            transition: 'transform .4s', '&:hover': {color: 'gold', transform: 'Scale(1.3)'}}}>
                Don't have an account?
            </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    >
                    <Box sx={{   position: 'absolute' as 'absolute',
                    textAlign: 'center',
                    width: '600px',
                    height: '600px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '15px',
                    boxShadow: 24,
                    bgcolor: '#FFFFDA',
                    p: 4, }}>                                    
                        <SignUp bgColor='transparent' />
                    </Box>
                </Modal> 
        </Box>
    </Grid>
  )
}

export default SignInContent
