import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
//import counterReducer from "../features/counter/counterSlice"
import { authSlice } from "secureid-react"
import userReducer from '../features/user/userSlice'
import bankReducer from '../features/bank-account/bankAccountSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userReducer,
    bank: bankReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
