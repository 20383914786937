import { Box } from '@mui/material'
import { BigInfoBoxNoImg, BigInfoBox } from './landingPage/BigInfoBox'
import InvestInFuture from '../../assets/images/MoneyFactory.png'
import Cards from './landingPage/CardContent/Cards'
import ShakeOnIt from '../../assets/icons/ShakeOnIt.png';

const Invest = () => {
  return (
    <Box>
      <Box>
        <Box sx={{ display: { xs: 'none', md: 'block'}, bgcolor: '#f4f5e1', height: '320px', pl: { md: '20%', lg: '35%'}, pr: { md: '15%'}, pt: {xs: '0', md: '3%'}}}>
          <BigInfoBoxNoImg SubTitle = "" Title = "Invest for the future. On your terms." 
          Content = "Together, we can help define your priorities for today and help you build a better tomorrow for you and your family." 
          Link = "See How" />
        </Box>  
        <Box sx={{ display: { xs: 'none', md: 'none', lg: 'none', xl: 'block' }, marginLeft: '-500px', marginTop: '-2.5%'}}>
          <img src={InvestInFuture} alt='Invest' style={{width: '800px'}} />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block', xl: 'none' }, marginLeft: '-675px', marginTop: '-7.5%'}}>
          <img src={InvestInFuture} alt='Invest' style={{width: '675px'}} />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block', lg: 'none' }, mx: 'auto', marginTop: '-5%'}}>
          <img src={InvestInFuture} alt='Invest' style={{width: '725px'}} />
        </Box>
        <Box sx={{display: {xs: 'block', md: 'none'}, bgcolor: 'white'}}>
          <BigInfoBox SubTitle = "" Title = "Invest for the future. On your terms." 
          Content = "Together, we can help define your priorities for today and help you build a better tomorrow for you and your family." 
          Link = "See How" ImgSrc={InvestInFuture} ImgName='Invest in the Future' />
        </Box>
      </Box>
      <Box sx={{mx: 'auto', mt: '5%', bgcolor: '#f4f5e1'}}>  
        <Box sx={{pt: '2%', pb: '2%'}}>
        <h1>Here's how we can work together</h1>
        <p>However you choose to invest, we're here to help you make the most of your money.</p>
        </Box>
        <Box sx={{display: { xs: 'block', xl: 'flex'}, mx: 'auto', pb: '2.5%'}}>          
            <Cards imageSource = {ShakeOnIt} title = "GoldenTime SelfDirected" description = "Build your investment portfolio on your own with unlimited $0 commission online trades." 
            link = "#" linkTitle ="Make your first investment" bulletPoints = {["No monthly fees"]} serviceFee = "Monthly Service Fee" extraInformation = "---" />     
            <Cards imageSource = {ShakeOnIt} title = "GoldenTime AdvisoryBoard" 
            description = "Work with a team of fiduciary advisors who will create a personalized financial plan, match you to expert-built portfolios and provide ongoing advice remotely." 
            link = "#" linkTitle ="Seek Out the AdvisoryBoard" bulletPoints = {["Meet with a team of experts","Get true guidance and assistance"]} 
            serviceFee = "Monthly Service Fee" extraInformation = "$94.99 / mo." />
            <Cards imageSource = {ShakeOnIt} title = "GoldenTime AI-Invest " 
            description = "Use AI to help guide you to creating a personalized financial strategy and build a custom investment portfolio." 
            link = "#" linkTitle ="Get AI Help Now" bulletPoints = {["Affordable","Simple to Use","24/7 Support"]} serviceFee = "Monthly Service Fee" extraInformation = "$44.99 / mo." />          
        </Box>
      </Box>
    </Box>
  )
}

export default Invest
