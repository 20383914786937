import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { BankAccountType, BankAccountTypeStrings, createBankAccountRequest, selectBankAccount, selectBankAccountType } from '../../features/bank-account/bankAccountSlice'
import { FormEvent } from 'react'

function OpenAccount() {
    const bankAccount = useAppSelector(selectBankAccount)
    const bankAccountType = useAppSelector(selectBankAccountType)
    const dispatch = useAppDispatch()     

    const handleSubmit = (event: FormEvent<HTMLButtonElement>) => {        
        dispatch(
            createBankAccountRequest({
                accountType: bankAccountType
            })
        )        
    }

    const shouldDisableButton = () => {
        if (bankAccountType === BankAccountType.Unknown) {
            return true;
        }
        else {
            return false;
        }
    }
  return (
    <Box sx={{ bgcolor: '#fff8e1', p: '2.5%', pt: '50px'}}>
        <FormControl sx={{ width: '200px' }}>
            <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
            <Select 
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Account Type"
                value={bankAccountType}
                defaultValue={BankAccountType.Unknown}
                onChange={(e) => {   
                        dispatch({
                            type: 'bankaccount/setBankAccountType', 
                            payload: e.target.value
                        })
                }}
            >
                <MenuItem value={BankAccountType.Unknown}>Select Account Type</MenuItem>
                <MenuItem value={BankAccountType.Checking}>{BankAccountTypeStrings[BankAccountType.Checking]}</MenuItem>
                <MenuItem value={BankAccountType.Savings}>{BankAccountTypeStrings[BankAccountType.Savings]}</MenuItem>
                <MenuItem value={BankAccountType.CreditCard}>{BankAccountTypeStrings[BankAccountType.CreditCard]}</MenuItem>
            </Select>
            <Button 
                variant='contained' 
                type='submit'         
                onClick={handleSubmit}       
                disabled={shouldDisableButton()}         
                sx={{ width: '50px', mt: '20px', mx: 'auto' }}
            >
                Submit
            </Button>
        </FormControl>
    </Box>
  )
}

export default OpenAccount