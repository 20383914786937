import React from 'react'
import InfoCardContent from './landingPage/CardContent/InfoCardContent'
import { Box, Card } from '@mui/material'
import SunsetDrive from '../../assets/images/sunset-drive.png'
import CarOnDisplay from '../../assets/images/car-display.png'
import Cards from './landingPage/CardContent/Cards'
import ShakeOnIt from '../../assets/icons/ShakeOnIt.png';
import Finance from '../../assets/icons/finance.png'
import Refinance from '../../assets/icons/refinance.png'
import Credit from '../../assets/icons/credit.png'
import Search from '../../assets/icons/search.png'
import { BigInfoBox, BigInfoBoxFlippedY } from './landingPage/BigInfoBox'

const Automotive = () => {
  return (
    <Box>
      <Box>
        <Box sx={{display: {xs:'none', sm:'none', md:'flex'}, backgroundColor:'transparent' }}>
          <img src={SunsetDrive} alt="Sunset Image" style={{marginLeft: 'auto', marginRight: 'auto', width: '100%'}} />
        </Box>      
        <Box sx={{display: {xs:'block', md:'none'}, backgroundColor:'transparent' }}>
          <img src={CarOnDisplay} alt="Sunset Image" style={{marginLeft: 'auto', marginRight: 'auto', width: '100%'}} />
        </Box>           
        <Card sx={{ display: {xs:'none', md:'block'}, maxWidth: 535, mt: '-25%', ml: '12%', color: 'white', bgcolor: 'black', opacity: '90%', p: '1%', borderRadius: '15px' }}>    
            <Cards imageSource = {Search} title = "Let us help you find the right car!" description = "What we'll do for you:" 
            link = "#" linkTitle ="Find Car" bulletPoints = {["Find & Secure the right dealership.", "Find & Secure the right loan."]} 
            serviceFee = "" extraInformation = "" />
        </Card>
        <Card sx={{ display: {xs:'block', md:'none'}, maxWidth: 525, mt: '-170%', mx: 'auto', color: 'white', bgcolor: 'black', opacity: '100%', p: '1%', borderRadius: '15px' }}>    
            <Cards imageSource = {Search} title = "Let us help you find the right car!" description = "What we'll do for you:" 
            link = "#" linkTitle ="Find Car" bulletPoints = {["Find & Secure the right dealership.", "Find the right loan."]} 
            serviceFee = "" extraInformation = "" />
        </Card>
      </Box>
      <Box sx={{ mt: {xs:'85%', md:'250px'}, }}>
        <Box sx={{bgcolor: 'white'}}>
          <BigInfoBox SubTitle = "GOLDENTIME AUTO NAVIGATION TOOL" Title = "Finance your new car with GoldenTime AutoNav" 
          Content = "Looking to purchase a new or used car? See what you pre-qualify for in minutes with no impact to your credit score. Browse the cars available to you online in the comfort of your own home." 
          Link = "GoldenTime Car Shopping" ImgSrc = {Finance} ImgName = "We will help" /> 
        </Box>
        <Box sx={{bgcolor: '#d5d4d9'}}>
          <BigInfoBoxFlippedY SubTitle = "GOLDENTIME COMPLETE APPROVAL" Title = "Receive an offer regardless of your credit." 
          Content = "View your exclusive offer and details. We just ask for a few small things from you." Link = "View Offers" ImgSrc = {Credit} ImgName = "Get Approved" />
        </Box>
        <Box sx={{bgcolor: 'white'}}>
          <BigInfoBox SubTitle = "GOLDENTIME AUTO REFINANCE" Title = "Start saving by refinancing your auto loan." 
          Content = "Lower your car payment, annual percentage rate (APR) or both. Check for savings with no impact to your credit score." 
          Link = "GoldenTime Refinancing" ImgSrc = {Refinance} ImgName = "Show The Way" />
        </Box>
        <Box sx={{bgcolor: '#d5d4d9'}}>
          <BigInfoBoxFlippedY SubTitle = "GOLDENTIME LOAN SERVICES" Title = "Already have a loan in the works?" 
          Content = "If you already have a loan that's taking forever to get approved, let us know, we can help." Link = "Set up Online Access" ImgSrc = {ShakeOnIt} ImgName = "Easy Loans" />
        </Box>
      </Box>
    </Box>
  )
}

export default Automotive
