import { Button, CardContent, CardMedia, Typography, Box, Grid } from '@mui/material'
import ShakeOnIt from '../../../../assets/icons/ShakeOnIt.png';

const InfoCardContent = () => {
  return (
        <Grid container sx={{ bgcolor: '#202322', color: 'white', borderRadius: '12px', p: '5%'}}>
            <CardMedia                     
                component="img"
                height="auto"
                image={ShakeOnIt}
                alt="Hand Shake"
                sx={{ width: '55%', textAlign: 'center', alignContent: 'center', mx: 'auto' }}
            />
            <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{opacity: '100%', pt:'2.5%'}}>
                Let us take care of you!
            </Typography>
            <Typography variant="body2" sx={{opacity: '75%'}}>
                We at Golden Time take banking to a new level! Forget those pesky fees and unjust dues and no return! We boast a wholesome 10% APY for savings accounts and a bountiful 
                15% to 20% on CD's! With an extremely low interest rate of 8.5%!
            </Typography>
            </CardContent>
            
            <Button size="medium" variant='contained' sx={{ bgcolor:'gold', color:'black', opacity: '100%', mx:'auto', borderRadius: '10px', transition: 'transform .3s', 
            '&:hover': {bgcolor: '#ffe1bf', color: 'black', transform: 'Scale(1.3)'}, mt:'2.5%' }}>
                See How
            </Button>
        </Grid>
  )
}

export default InfoCardContent
