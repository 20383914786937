import { Box, Button, Dialog, Typography } from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { getTransactionHistory, selectBankAccounts, selectTotalPages, TransactionType } from '../../features/bank-account/bankAccountSlice'
import { Demodal, useModal } from 'demodal'
import { muiDialog } from 'demodal/material-ui'
import { useState } from 'react'
import { DisabledByDefault } from '@mui/icons-material'


export const AddTransactionHistoryModal = Demodal.create(({accountId}:{accountId: number}) => {
    const bankAccounts = useAppSelector(selectBankAccounts)
    let [pageNumber, setPageNumber] = useState(1); 
    const addTransactionHistoryModal = useModal()
    const dispatch = useAppDispatch()

    var accountIndex = bankAccounts.findIndex(bankAcc => bankAcc.accountId === accountId)   

    const handleDownload = () => {                
        
    }

    
    const handleNextPage = async () => {   
        pageNumber++;   
        setPageNumber(pageNumber)    
        await dispatch(getTransactionHistory({accountId: accountId, pageNumber: pageNumber, pageSize: 25}))
    }

    var prevDisabled = pageNumber <= 1
    const handlePreviousPage = async () => { 
        pageNumber--;   
        setPageNumber(pageNumber)   
        await dispatch(getTransactionHistory({accountId: accountId, pageNumber: pageNumber, pageSize: 25}))
    }

  return (
    <Box sx={{ overflowX: 'hidden'}}>        
        <Dialog  sx={{ overflowX: 'hidden'}}
            {...muiDialog(addTransactionHistoryModal)}
            >                              
                <Box sx={{ overflowX: 'hidden', bgcolor: '#fff8e1', height: '100%', mx: 'auto', p: '2.5%', pt: '20px' }}> 
                    <Typography sx={{textAlign: 'center', px: '25px', fontSize: '25px'}} variant='h4'>Transaction History For: {accountId}</Typography>                    
                    <Box sx={{ width: '100%'}}>                                    
                        { bankAccounts[accountIndex].transactions && bankAccounts[accountIndex].transactions.map((tx)=>
                            <Box sx={{ textAlign: 'center', display: 'grid', px: '5px', mx: '15px', p: '8.5px'}}>                                
                               <Typography sx={{ px: '2.5px', bgcolor: '#25523640' }}> {tx.dateCreated.substring(0,10)}</Typography>
                               <Typography sx={{ px: '2.5px'}}>Id: {tx.transactionId}</Typography>                                
                               <Typography sx={{ px: '2.5px'}}>${tx.amount}</Typography>
                               <Typography sx={{ px: '2.5px'}}>{TransactionType[tx.transactionTypeId]}</Typography>
                            </Box>
                            )            
                        }   
                        { bankAccounts[accountIndex].pagingDetails &&
                            <Box sx={{ mx: 'auto', textAlign: 'center' }}>
                                <Typography>Page: {bankAccounts[accountIndex].pagingDetails.pageNumber} of {bankAccounts[accountIndex].pagingDetails.totalPages}</Typography>
                            </Box>
                        }
                        <Box sx={{ display: 'flex' }}>    
                                                                               
                            <Button 
                                sx={{ mr: '5%', ml: '8.5%' }}
                                disabled={prevDisabled} 
                                onClick={()=>handlePreviousPage()}>
                                    Previous Page
                            </Button> 
                            <Button 
                                sx={{ mr: '5%', ml: '5%' }}
                                disabled={pageNumber === bankAccounts[accountIndex].pagingDetails.totalPages}
                                onClick={()=>handleNextPage()}>
                                    Next Page
                            </Button>
                        </Box>  
                        
                        <Button  sx={{ ml: '28.5%'}}
                            onClick={()=>handleDownload()}>
                                Download PDF
                        </Button>
                    </Box>                    
                </Box>
        </Dialog>
    </Box>
  )
})