
import "./App.css"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from "./components/pages/landingPage/LandingPage"
import Navbar from "./components/persistentElements/Navbar"
import Checking from "./components/pages/Checking";
import Education from "./components/pages/Education";
import Invest from "./components/pages/Invest";
import Savings from "./components/pages/Savings";
import Loans from "./components/pages/Loans";
import Automotive from "./components/pages/Automotive";
import CreditCards from "./components/pages/CreditCards";
import Footer from "./components/persistentElements/Footer";
import { useEffect } from "react";
import About from "./components/pages/About";
import Newsroom from "./components/pages/Newsroom";
import Technology from "./components/pages/Technology";
import {  SecureIdConfiguration } from "secureid-react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import Dashboard from "./components/pages/Dashboard";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ProductionConfig } from "./config";
import AccountManagement from "./components/pages/AccountManagement";
import { Profile } from "./components/pages/Profile";
// import PayAndTransfer from "./components/pages/PayAndTransfer";
import Accounts from "./components/pages/Accounts";
import OpenAccount from "./components/pages/OpenAccount";
import { Demodal } from 'demodal';

function App() {
  const dispatch = useAppDispatch()

  useEffect(()=> {
    dispatch({type:'auth/configureAuthzyEndpoints', 
    payload: ProductionConfig
  })
  },[])

  return (
    <div className="App pageBg"> 
 
      <Router>
        <Navbar />
          <Routes>        
            <Route path="/" element={<LandingPage />} />
            <Route path="Checking" element={<Checking />} />
            <Route path="Education" element={<Education />} />
            <Route path="Invest" element={<Invest />} />
            <Route path="Loans" element={<Loans />} />
            <Route path="Savings" element={<Savings />} />
            <Route path="Credit Cards" element={<CreditCards />} />
            <Route path="Automotive" element={<Automotive />} />
            <Route path="About" element={<About />} />
            <Route path="Newsroom" element={<Newsroom />} />
            <Route path="Technology" element={<Technology />} />
            <Route element={<ProtectedRoute />} />
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="AccountManagement" element={<AccountManagement />} />
              <Route path="Accounts" element={<Dashboard />} />
              <Route path="Profile" element={<Profile />} />
              {/* <Route path="Pay & Transfer" element={<PayAndTransfer />} />               */}
              <Route path="OpenAccount" element={<OpenAccount />} />              
          </Routes>
        <Footer />
      </Router>
      <Demodal.Container/>
    </div>
  )
}

export default App
